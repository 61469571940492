import React, { useState } from "react"
import Input from '../components/Input'
import { useHistory } from 'react-router-dom';
import Swal from "sweetalert2"

export default function KYC() {
  const history = useHistory();
  const [names, setNames] = useState('')
  const [lastNames, setLastNames] = useState('')
  const [identificationNumber, setIdentificationNumber] = useState('')
  const [phone, setPhone] = useState('')
  const [idUploaded, setIdUploaded] = useState('')
  const [verifUploaded, setVerifUploaded] = useState('')
  const handleKYC = () => {
    if (idUploaded !== 'done' || verifUploaded !== 'done') {
      Swal.fire({ icon: 'error', title: 'Error', text: 'Tanto la foto de su cédula como la de su verificación deben haber sido subidas para poder enviar su solicitud.' });
      return
    }
    fetch('/api/app/request-kyc', {
      method: 'POST',
      body: JSON.stringify({
        names,
        lastNames,
        identificationNumber,
        phone
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
      .then(res => res.json())
      .then(response => {
        console.log(response);
        if (response.ok) {
          Swal.fire({ icon: 'success', title: 'Sus datos han sido enviados' });
          history.push('/')
        } else {
          Swal.fire({ icon: 'error', title: 'Error', text: response.error });
        }
      })
  }
  const fileUpload = (event, pathEnd, cb, failCb) => {
    const files = event.target.files
    if (files[0].size > 5000000) {
      Swal.fire({ icon: 'error', title: 'el archivo no puede sobrepasar los 5 MB' });
    }
    const data = new FormData()
    data.append('file', files[0])
    fetch('/api/app/upload-' + pathEnd, {
      method: 'POST',
      body: data,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
      .then(response => response.json())
      .then(data => {
        cb(data)
      })
      .catch(error => {
        console.error(error)
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Subida de imagen fallida, verifique que su imagen no exceda los 5 megabytes y que su conexión esté activa.'
        });
        failCb()
      })
  }
  const onIDFileChange = e => {
    setIdUploaded('uploading')
    fileUpload(e, 'identification', data => {
      setIdUploaded('done')
    }, () => {
      setIdUploaded('')
    })
  }
  const onVerifFileChange = e => {
    setVerifUploaded('uploading')
    fileUpload(e, 'verification-picture', data => {
      setVerifUploaded('done')
    }, () => {
      setVerifUploaded('')
    })
  }
  return (
    <div className="home">
      <div className="sign-box kyc-box" style={{ marginTop: '0em' }}>
        <h2>Datos personales</h2>
        <div className="inputs">
          <Input name="Nombres" label="names" onChange={e => setNames(e.target.value)} value={names} />
          <Input name="Apellidos" label="lastNames" onChange={e => setLastNames(e.target.value)} value={lastNames} />
          <Input name="Cédula de identidad" label="identificationNumber" onChange={e => setIdentificationNumber(e.target.value)} value={identificationNumber} />
          <Input name="Número de teléfono" label="phone" onChange={e => setPhone(e.target.value)} value={phone} />
        </div>
        <div className="files ">
          <label className="align-left-hr">Foto de cédula de identidad</label>
          <label className="file-label" htmlFor="id-photo">
            {idUploaded === '' && <>Click aquí para subir foto</>}
            {idUploaded === 'uploading' && <>Subiendo foto</>}
            {idUploaded === 'done' && <>Foto subida, click aquí para cambiarla</>}
          </label>
          <p className="explanation-text">
            La foto debe mostrar su cédula de identidad con claridad y no debe ser mayor a 5MB.
          </p>
          <label className="align-left-hr">Foto de verificación</label>
          <label className="file-label" htmlFor="verification-photo">
            {verifUploaded === '' && <>Click aquí para subir foto</>}
            {verifUploaded === 'uploading' && <>Subiendo foto</>}
            {verifUploaded === 'done' && <>Foto subida, click aquí para cambiarla</>}
          </label>
          <p className="explanation-text">
            Esta foto debe mostrar su rostro y el parecido con su cédula de identidad debe ser reconocible.
            También debe sostener un papel con la fecha actual que diga "Teller" y su número de cédula. No debe ser mayor a 5MB.
          </p>
          <p className="explanation-text"></p>
          <input type="file" id="id-photo" style={{ display: 'none' }} onChange={onIDFileChange} />
          <input type="file" id="verification-photo" style={{ display: 'none' }} onChange={onVerifFileChange} />
        </div>
        <button onClick={handleKYC}>Solicitar autorización</button>
      </div>
    </div>
  )
}
