import React from "react"
import Loader from "./Loader"

export default function PageLoading({ loading }) {
  if (!loading) {
    return null
  }
  return (
    <div className="page-loading">
      <div>
        <Loader />
        <h3>No cierre esta página. Hacerlo puede poner sus fondos en riesgo</h3>
      </div>
    </div>
  )
}
