import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Swal from "sweetalert2"
import Input from '../components/Input'
import { useHistory } from 'react-router-dom';
import { NETWORK_NAME } from "../web3/constants";

const statusTranslator = {
  requested: 'Esperando aprobación',
  rejected: 'Rechazada',
  finished: 'Finalizada',
  approved: 'Aceptada',
}

export default function MyTransactions() {
  const history = useHistory();
  const [transactions, setTransactions] = useState(null)
  useEffect(() => {
    const fetchTransactions = async () => {
      const transactions = await (await fetch('/api/app/my-transactions', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      })).json()
      if (transactions.ok) {
        setTransactions(transactions.data)
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "No podemos conseguir sus transacciones. Por favor revise su conexión a internet"
        });
      }
    }
    fetchTransactions()
  }, [])
  let requestedTransactions = [], finishedTransactions = []
  if (transactions && transactions.length) {
    transactions.forEach(t => {
      if (t.status === 'requested') {
        requestedTransactions.push(t)
      } else {
        finishedTransactions.push(t)
      }
    })
  }
  return (
    <div className="home">
      <div className="transactions">
        <h2 style={{ margin: '1em 0' }}>Mis transacciones:</h2>
        <div>
          {!transactions && <>Cargando</>}
          {!!transactions && !transactions.length && <h2>No hay transacciones registradas</h2>}
          {!!transactions && !!transactions.length && <>
            {!!requestedTransactions.length &&
              <>
                <h4>Transacciones en espera</h4>
                <div className="table-container">
                  <table>
                    <thead>
                      <tr>
                        <th>status</th>
                        <th>transacción</th>
                        <th>monto a retirar</th>
                        <th>monto pagado</th>
                        <th>blockchain</th>
                      </tr>
                    </thead>
                    <tbody>
                      {requestedTransactions.map((t, i) => (
                        <tr key={i}>
                          <td>{statusTranslator[t.status]}</td>
                          <td>{`${t.transactionHash.slice(0, 6)}...${t.transactionHash.slice(-4)}`}</td>
                          <td>{t.cashAmount} USD</td>
                          <td>{t.amount}</td>
                          <td>{NETWORK_NAME[+t.networkId]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            }
            {!!finishedTransactions.length && <>
              <h4>Historial</h4>
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>status</th>
                      <th>transacción</th>
                      <th>monto a retirar</th>
                      <th>monto pagado</th>
                      <th>blockchain</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.filter(t => t.status !== 'requested').map((t, i) => (
                      <tr key={i}>
                        <td>{statusTranslator[t.status]}</td>
                        <td>{`${t.transactionHash.slice(0, 6)}...${t.transactionHash.slice(-4)}`}</td>
                        <td>{t.cashAmount} USD</td>
                        <td>{t.amount}</td>
                        <td>{NETWORK_NAME[+t.networkId]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>}
          </>
          }
        </div>
      </div>
    </div>
  )
}
