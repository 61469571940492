import React, { useReducer, useEffect, useCallback, createContext } from "react"

import { Web3Reducer } from "./reducer"

// WEB3 CONNECTION PACKAGES
import Web3 from "web3"
import Web3Modal from "web3modal"
// import WalletConnectProvider from "@walletconnect/web3-provider"
// import Torus from "@toruslabs/torus-embed"
// import Authereum from "authereum"
import * as sigUtils from "eth-sig-util";

import { ERC20_ABI, VAULT } from "./constants"
import Swal from "sweetalert2";

import notify from "../utils/notify"

import history from "../history"

let web3

const initialState = {
  loading: true,
  account: null,
  networkId: null,
  sendStableCoin: null,
  signTransaction: null,
  contracts: {
    token: null,
    vesting: null,
  },
}

export const Web3Context = createContext(initialState)

export const Web3Provider = ({ children }) => {
  const [state, dispatch] = useReducer(Web3Reducer, initialState)

  const { account, contracts } = state

  // STATE MANAGEMENT
  const setAccount = (account) => {
    dispatch({
      type: "SET_ACCOUNT",
      payload: account,
    })
  }

  const setNetworkId = (networkId) => {
    dispatch({
      type: "SET_NETWORK_ID",
      payload: networkId,
    })
  }

  const setProtocol = async (accounts) => {
    setAccount(accounts[0])
    // Contract Instances
    const networkId = await web3.givenProvider.networkVersion
    setNetworkId(networkId)
  }

  // === HELPERS === //
  const logout = () => {
    dispatch({
      type: "CLEAR_STATE",
      payload: initialState,
    })
    localStorage.setItem("WEB3_CONNECT_CACHED_PROVIDER", null)
    history.push("/")
  }
  const toWei = (value) => web3.utils.toWei(String(value))
  const fromWei = (value) => Number(web3.utils.fromWei(String(value)))
  const toBN = (value) => new web3.utils.BN(String(value))

  // === MAIN FUNCTIONS === //

  // Connect Web3 wallet and set state (contracts, roles, account)
  const connectWeb3 = useCallback(async () => {
    if (!window.localStorage.getItem('token') || !window.ethereum) {
      return
    }
    // Web3 Modal
    const providerOptions = {
      // walletconnect: {
      //   package: WalletConnectProvider, // required
      //   options: {
      //     infuraId: "36bbdc3ed5bd449fad0374a2e07b850a", // required
      //   },
      // },
      // torus: {
      //   package: Torus, // required
      //   options: {
      //     networkParams: {
      //       host: "https://mainnet.infura.io/v3/36bbdc3ed5bd449fad0374a2e07b850a", // optional
      //       networkId: 1, // optional
      //     },
      //     config: {
      //       buildEnv: "production", // optional
      //     },
      //   },
      // },
      // authereum: {
      //   package: Authereum,
      // },
    }

    try {
      const web3Modal = new Web3Modal({
        // network: "mainnet", // optional
        cacheProvider: true, // optional
        providerOptions, // required
        theme: "light",
      })

      const provider = await web3Modal.connect()

      web3 = new Web3(provider)
      window.web3 = web3

      const _accounts = await web3.eth.getAccounts()
      await setProtocol(_accounts)

      notify("success", "Tu wallet ha sido conectada", 1500)

      window.ethereum.on("chainChanged", () => {
        document.location.reload()
      })

      //If accounts change
      window.ethereum.on("accountsChanged", (accounts) => {
        document.location.reload()
      })
    } catch (error) {
      notify("error", "No pudimos conectar tu wallet!")
      console.log(error.message)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const signText = async message => {
    web3.eth.sign(web3.utils.keccak256(message), account, (err, result) => {
      console.log(err, message, result)
      return { err, result, message }
    })
  }

  const sendStableCoin = async (tokenAddress, amount) => {
    const decimalsPoints = (amount + '').includes('.') ? (amount + '').split('.')[1].length : 0
    const stableCoin = new web3.eth.Contract(
      ERC20_ABI,
      tokenAddress
    )
    amount = (amount + '').replace('.', '')
    const decimals = await stableCoin.methods.decimals().call()
    console.log(amount);
    const BN = Web3.utils.BN
    console.log(decimals);
    const parsedAmount = new BN(amount + '0'.repeat(decimals - decimalsPoints))
    console.log(amount + '0'.repeat(decimals - decimalsPoints));
    console.log(parsedAmount);
    const tx = await stableCoin.methods.transfer(VAULT, parsedAmount).send({ from: account })
    return tx
  }

  const signTransaction = (stableCoin, amount, actualAmount, transactionHash, deliveryLocation, callback) => {
    const msgParams = JSON.stringify({
      domain: {
        chainId: state.networkId,
        name: 'Teller - Verify Identity',
        verifyingContract: '0xCcCCccccCCCCcCCCCCCcCcCccCcCCCcCcccccccC',
        version: '1',
      },
      message: {
        amount: actualAmount + '',
        stableCoin: stableCoin.toLowerCase(),
        cashAmount: amount + ''
      },
      primaryType: 'Mail',
      types: {
        EIP712Domain: [
          { name: 'name', type: 'string' },
          { name: 'version', type: 'string' },
          { name: 'chainId', type: 'uint256' },
          { name: 'verifyingContract', type: 'address' },
        ],
        Mail: [
          { name: 'amount', type: 'string' },
          { name: 'stableCoin', type: 'string' },
          { name: 'cashAmount', type: 'string' },
        ],
      },
    });

    const from = state.account;

    const params = [from, msgParams];
    const method = 'eth_signTypedData_v4';

    console.log('SIGN');
    web3.currentProvider.sendAsync(
      {
        method,
        params,
        from,
      },
      async (err, result) => {
        console.log('CALLBACK');
        if (err) return console.log(err);
        if (result.error) return console.error('ERROR', result);
        if (err || result.error) {
          Swal.fire({ icon: 'error', title: 'Error', text: "La firma para confirmar su transacción ha fallado. Por favor contacte a soporte para recuperar sus fondos" });
        }
        console.log('signature: ', result.result);
        console.log('utils: ', sigUtils);
        console.log(msgParams);
        const rawResponse = await fetch(`/api/app/buy-cash/`, {
          body: JSON.stringify({
            signature: result.result,
            transactionHash: transactionHash,
            amount: actualAmount,
            cashAmount: amount,
            userAddress: state.account,
            networkId: state.networkId,
            deliveryLocation: deliveryLocation,
          }),
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
        const content = await rawResponse.json();
        callback()
        console.log(content);
      }
    );
  };
  // Connect web3 on app load
  useEffect(() => {
    connectWeb3()
  }, [connectWeb3])

  return (
    <Web3Context.Provider
      value={{
        ...state,
        connectWeb3,
        logout,
        toWei,
        fromWei,
        toBN,
        signText,
        sendStableCoin,
        signTransaction,
        networkId: state.networkId,
      }}
    >
      {children}
    </Web3Context.Provider>
  )
}
