import React, { useContext } from "react"
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"
// import history from "./history"
// COMPONENTS
import Header from "./components/Header"
import PublicHeader from "./components/PublicHeader"
import Buy from "./views/Buy"
import KYC from "./views/KYC"
import SignUp from "./views/SignUp"
import SignIn from "./views/SignIn"
import MyTransactions from "./views/MyTransactions"
import Admin from "./views/Admin"

import { AppContext } from "./context"

export default function Router() {
  const { token } = useContext(AppContext)
  if (token) {
    return (
      <>
        <BrowserRouter>
          <Header />
          <main>
            <Switch>
              <Route path="/" exact>
                <MyTransactions />
              </Route>
              <Route path="/buy" exact>
                <Buy />
              </Route>
              <Route path="/kyc" exact>
                <KYC />
              </Route>
              <Route path="/teller-admin" exact>
                <Admin />
              </Route>
              <Redirect to="/" />
            </Switch>
          </main>
        </BrowserRouter>
      </>
    )
  } else {
    return (
      <>
        <BrowserRouter>
          <PublicHeader />
          <main>
            <Switch>
              <Route path="/signIn" exact>
                <SignIn />
              </Route>
              <Route path="/signUp" exact>
                <SignUp />
              </Route>
              <Redirect to="/signIn" />
            </Switch>
          </main>
        </BrowserRouter>
      </>
    )

  }
}
