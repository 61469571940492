import React, { useState } from "react"
// import history from "./history"
// COMPONENTS
import Router from "./Router"
import { AppContext } from "./context"
import PageLoading from "./components/PageLoading"

export default function App() {
  const [token, setToken] = useState(localStorage.getItem('token'))
  const [pageLoading, setPageLoading] = useState(false)
  const login = (token) => {
    localStorage.setItem('token', token)
    setToken(token)
  }
  const logout = () => {
    localStorage.removeItem('token')
    setToken(undefined)
  }
  return (
    <AppContext.Provider value={{ token, login, logout, pageLoading, setPageLoading }}>
      <Router />
      <PageLoading loading={pageLoading} />
    </AppContext.Provider>
  )
}
