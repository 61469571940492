import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Image } from "react-bootstrap"
import Logo from "../assets/LOGO.svg"

export default function PublicHeader() {
  const [activeMenu, setActiveMenu] = useState(false)

  const navItems = (
    <>
      <div className="mr-4">
        <Link to="/admin" onClick={() => setActiveMenu(false)}>
          Iniciar sesión
        </Link>
      </div>
      <div className="mr-4">
        <Link to="/signUp" onClick={() => setActiveMenu(false)}>
          Crear cuenta
        </Link>
      </div>
    </>
  )

  return (
    <header>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%' }}>
        <Image
          onClick={() => setActiveMenu(!activeMenu)}
          id="logo-image"
          src={Logo}
          alt="eth-logo"
        />
        <div className="desktop desktop-links">{navItems}</div>
      </div>
    </header>
  )
}
