import React, { useEffect, useState } from "react"
import Swal from "sweetalert2"
import { useHistory } from 'react-router-dom';
import { NETWORK_NAME } from "../web3/constants";
import Modal from "../components/Modal";

const statusTranslator = {
  requested: 'Esperando aprobación'
}

export default function Admin() {
  const history = useHistory();
  const [transactions, setTransactions] = useState(null)
  const [pendingUsers, setPendingUsers] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedTransaction, setSelectedTransaction] = useState(null)
  const [selectedTransactionOwner, setSelectedTransactionOwner] = useState(null)
  const [idPhoto, setIdPhoto] = useState(null)
  const [verifPhoto, setVerifPhoto] = useState(null)
  const fetchTransactions = async () => {
    try {
      const transactions = await (await fetch('/api/admin/requested-transactions', {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      })).json()
      if (transactions.ok) {
        setTransactions(transactions.data)
      } else {
        if (transactions.error === 'user is not admin') {
          history.push('/')
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: "No podemos conseguir las transacciones pendientes. Por favor revise su conexión a internet. Si el problema persiste contacte al equipo técnico."
          });
        }
      }
    } catch (e) {
      console.log('error', e)
    }
  }
  const fetchRequestedUsers = async () => {
    try {
      const users = await (await fetch('/api/admin/requested-accounts', {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      })).json()
      if (users.ok) {
        setPendingUsers(users.data)
      } else {
        if (users.error === 'user is not admin') {
          history.push('/')
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: "No podemos conseguir las peticiones pendientes. Por favor revise su conexión a internet. Si el problema persiste contacte al equipo técnico."
          });
        }
      }
    } catch (e) {
      console.log('error', e)
    }
  }
  useEffect(() => {
    fetchTransactions()
    fetchRequestedUsers()
  }, [])
  const getUserImages = async ({ _id }) => {
    fetch('/api/admin/getIdPhoto?id=' + _id, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    })
      .then(response => response.blob())
      .then(imageBlob => {
        // Then create a local URL for that image and print it 
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setIdPhoto(imageObjectURL)
      });
    fetch('/api/admin/getVerifPhoto?id=' + _id, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    })
      .then(response => response.blob())
      .then(imageBlob => {
        // Then create a local URL for that image and print it 
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setVerifPhoto(imageObjectURL)
      });
  }
  const fetchTransactionOwnerData = async id => {
    const data = await (await fetch('/api/admin/get-user-data?_id=' + encodeURIComponent(id), {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    })).json()
    setSelectedTransactionOwner(data.data)
  }
  const transactionButtonClick = status => {
    fetch('/api/admin/process-transaction', {
      method: 'POST',
      body: JSON.stringify({
        newStatus: status,
        transactionId: selectedTransaction._id
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    })
      .then(response => response.json())
      .then(response => {
        if (!response.ok) {
          Swal.fire({
            icon: 'error',
            title: response.message,
            confirmButtonText: 'Aceptar'
          })
        } else {
          fetchTransactions()
          setSelectedTransaction(null)
          setSelectedTransactionOwner(null)
        }
      })
  }
  const kycButtonClick = status => {
    fetch('/api/admin/process-kyc?status=' + status, {
      method: 'POST',
      body: JSON.stringify({
        newStatus: status,
        userId: selectedUser._id
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    })
      .then(response => response.json())
      .then(response => {
        if (!response.ok) {
          Swal.fire({
            icon: 'error',
            title: response.message,
            confirmButtonText: 'Aceptar'
          })
        } else {
          fetchRequestedUsers()
          setSelectedUser(null)
        }
      })
  }
  return (
    <div className="home">
      <div className="transactions">
        <h2 style={{ marginBottom: '1em' }}>Panel de administrador</h2>
        <div>
          <h4>Transacciones pendientes</h4>
          {!transactions && <>Cargando...</>}
          {!!transactions && !transactions.length && <>No hay transacciones pendientes</>}
          {!!transactions && !!transactions.length && <>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>status</th>
                    <th>transacción</th>
                    <th>monto a retirar</th>
                    <th>monto pagado</th>
                    <th>blockchain</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((t, i) => (
                    <tr key={i} onClick={() => { setSelectedTransaction(t); fetchTransactionOwnerData(t.userId) }}>
                      <td>{statusTranslator[t.status]}</td>
                      <td>{`${t.transactionHash.slice(0, 6)}...${t.transactionHash.slice(-4)}`}</td>
                      <td>{t.cashAmount} USD</td>
                      <td>{t.amount}</td>
                      <td>{NETWORK_NAME[+t.networkId]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
          }
          <h4>Usuarios pendientes</h4>
          {!pendingUsers && <>Cargando...</>}
          {!!pendingUsers && !pendingUsers.length && <>No hay transacciones pendientes</>}
          {!!pendingUsers && !!pendingUsers.length && <>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>correo</th>
                    <th>fecha</th>
                    <th>nombres</th>
                    <th>apellidos</th>
                    <th>cédula</th>
                    <th>teléfono</th>
                  </tr>
                </thead>
                <tbody>
                  {pendingUsers.map((u, i) => (
                    <tr key={i} onClick={() => { setSelectedUser(u); getUserImages(u) }}>
                      <td>{u.email}</td>
                      <td>{(new Date(u.createdAt)).toLocaleDateString('es-VE')}</td>
                      <td>{u.names}</td>
                      <td>{u.lastNames}</td>
                      <td>{u.identificationNumber}</td>
                      <td>{u.phone}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
          }
        </div>
      </div>
      {selectedUser && <Modal close={() => setSelectedUser(null)}>
        <h3>Solicitud de aprobación de datos personales</h3>
        <div>email: {selectedUser.email}</div>
        <div>fecha de solicitud{(new Date(selectedUser.createdAt)).toLocaleDateString('es-VE')}</div>
        <div>nombres: {selectedUser.names}</div>
        <div>apellidos: {selectedUser.lastNames}</div>
        <div>cédula: {selectedUser.identificationNumber}</div>
        <div>número de teléfono: {selectedUser.phone}</div>
        <div>
          Cédula:
          {idPhoto ? <img className="kyc-pic" src={idPhoto} alt="" /> : <>Cargando...</>}
        </div>
        <div>
          Foto de verificación:
          {verifPhoto ? <img className="kyc-pic" src={verifPhoto} alt="" /> : <>Cargando...</>}
        </div>
        <button onClick={() => kycButtonClick('rejected')}>Rechazar</button>
        <button onClick={() => kycButtonClick('approved')}>Aprobar</button>
      </Modal>}
      {selectedTransaction && <Modal close={() => setSelectedTransaction(null)}>
        <h3>Solicitud de transacción</h3>
        <div>monto en efectivo: {selectedTransaction.cashAmount}</div>
        <div>monto pagado: {selectedTransaction.amount}</div>
        <div>lugar de entrega: {+selectedTransaction.amount > 2000 ? 'agencia' : selectedTransaction.deliveryLocation}</div>
        <div>número de teléfono: {selectedTransactionOwner ? selectedTransactionOwner.phone : 'cargando...'}</div>
        <div>nombres: {selectedTransactionOwner ? selectedTransactionOwner.names : 'cargando...'}</div>
        <div>apellidos: {selectedTransactionOwner ? selectedTransactionOwner.lastNames : 'cargando...'}</div>
        <div>correo: {selectedTransactionOwner ? selectedTransactionOwner.email : 'cargando...'}</div>
        <button onClick={() => transactionButtonClick('rejected')}>Rechazar</button>
        <button onClick={() => transactionButtonClick('approved')}>Aprobar</button>
      </Modal>}
    </div>
  )
}
