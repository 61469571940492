import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { Image } from "react-bootstrap"
import Logo from "../assets/LOGO.svg"
import { AppContext } from "../context";
import { Web3Context } from "../web3"
import metamask from '../assets/metamask.svg';

export default function Header() {
  const { connectWeb3, account, logout } = useContext(Web3Context)
  const { logout: appLogout } = useContext(AppContext)
  const [activeMenu, setActiveMenu] = useState(false)


  const navItems = (
    <>
      <div>
        <Link to="/" onClick={() => setActiveMenu(false)}>
          Inicio
        </Link>
      </div>{" "}
      <div className="mr-4">
        <Link to="/buy" onClick={() => setActiveMenu(false)}>
          Comprar efectivo
        </Link>
      </div>
      <div className="mr-4">
        <Link to="/signIn" onClick={() => { setActiveMenu(false); appLogout() }}>
          Salir
        </Link>
      </div>
    </>
  )

  return (
    <header>
      <div>
        <Image
          onClick={() => setActiveMenu(!activeMenu)}
          id="logo-image"
          src={Logo}
          alt="eth-logo"
        />
        <div className="desktop desktop-links">{navItems}</div>
      </div>
      <div>
        {account ? (
          <>
            <div sm="8" className="align-self-center desktop">
              <h5 className="text-right">
                Conectado:{" "}
                <a
                  href={`https://etherscan.io/address/${account}`}
                  target="_blank"
                  rel="noreferrer"
                  className="account-link"
                >
                  {account && account.substring(0, 5) + "..." + account.substring(39, 42)}
                </a>
              </h5>
            </div>

            <div>
              <button onClick={logout}>Desconectar Wallet</button>
            </div>
          </>
        ) : (
          window.ethereum ?
            <button onClick={connectWeb3}>Conectar Wallet</button>
            :
            <a href="https://metamask.io/" target="_blank" rel="noreferrer">
              <button className="header-metamask-button">
                <span className="small-hidden">
                  Instala tu wallet
                </span>
                <img style={{ display: 'inline-block' }} src={metamask} alt="" />
              </button>
            </a>
        )}
      </div>
      <section className={"mobile floating-menu" + (activeMenu ? " active" : "")}>
        {navItems}
      </section>
    </header>
  )
}
