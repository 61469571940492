export const VAULT = "0x5C3050b5f8F83906F1e1Fcdbd3545A24F3f75aBE"

export const USDT_ETH = "0xdac17f958d2ee523a2206206994597c13d831ec7"
export const USDT_BSC = "0x55d398326f99059fF775485246999027B3197955"
export const USDT_RINKEBY = "0x732b26736AF5B0b429a531300c1539aA96e5374f"

export const USDC_ETH = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"
export const USDC_BSC = "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d"
export const USDC_RINKEBY = "0x732b26736AF5B0b429a531300c1539aA96e5374f"


export const NETWORK_NAME = {
  1: "Ethereum",
  4: "Rinkeby",
  10: "Optimistic Ethereum",
  42: "Ethereum",
  56: "Binance Smart Chain",
  80001: "Polygon",
  43113: "Avalanche",
  43114: "Avalanche",
  97: "Binance",
  1287: "Moonbase",
};

export const BLOCKCHAIN_SCANNER_BASE_LINK = {
  1: "https://etherscan.io/",
  4: "https://rinkeby.etherscan.io/",
  10: "https://optimistic.etherscan.io/",
  42: "https://kovan.etherscan.io/",
  56: "https://bscscan.com/",
  80001: "https://mumbai.polygonscan.com/",
  43113: "https://testnet.snowtrace.io/",
  43114: "https://snowtrace.io/",
  97: "https://testnet.bscscan.com/",
  1287: "https://moonbase.moonscan.io/",
};

export const addressesByNetwork = {
  1: [
    { address: USDT_ETH, name: 'USDT' },
    { address: USDC_ETH, name: 'USDC' },
  ],
  4: [
    { address: USDT_RINKEBY, name: 'USDT' },
    { address: USDC_RINKEBY, name: 'USDC' },
  ],
  56: [
    { address: USDT_BSC, name: 'USDT' },
    { address: USDC_BSC, name: 'USDC' },
  ],
}

export const ERC20_ABI = [
  {
    "inputs": [],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "Approval",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "Transfer",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      }
    ],
    "name": "allowance",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "approve",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "decimals",
    "outputs": [
      {
        "internalType": "uint8",
        "name": "",
        "type": "uint8"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "subtractedValue",
        "type": "uint256"
      }
    ],
    "name": "decreaseAllowance",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "addedValue",
        "type": "uint256"
      }
    ],
    "name": "increaseAllowance",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "name",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "symbol",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalSupply",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "transfer",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "transferFrom",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  }
]
