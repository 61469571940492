import React, { useState, useContext, useEffect } from "react"
import { useHistory } from 'react-router-dom';
import arrow from '../assets/arrow.svg'
import metamask from '../assets/metamask.svg';
import { addressesByNetwork } from "../web3/constants";
import { Web3Context } from "../web3"
import { AppContext } from "../context";
import Swal from "sweetalert2";

const locations = [
  'CC El Recreo',
  'CC Sambil',
  'Plaza Altamira',
  'CC Metrocenter',
  'Plaza Bolivar - Capitolio',
]

export default function Buy() {
  const { networkId, sendStableCoin, signTransaction, account } = useContext(Web3Context)
  const { setPageLoading } = useContext(AppContext)
  const [amount, setAmount] = useState(0)
  const [comissionBase, setComissionBase] = useState(3)
  const [comissionPercent, setComissionPercent] = useState(2)
  const [minAmount, setMinAmount] = useState(20)
  const [selectedStablecoin, setSelectedStablecoin] = useState(null)
  const [deliveryLocation, setDeliveryLocation] = useState('')

  useEffect(() => {
    const checkKYCStatus = async () => {
      try {
        const status = await (await fetch(`/api/app/my-kyc-status/`, {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        })).json()
        if (status.level === 'approved') {
          return
        }
        let message
        if (status.level === 'zero') {
          message = 'Por favor llene un formulario para poder autorizar la compra de efectivo.'
        }
        if (status.level === 'requested') {
          message = 'Su petición está siendo revisada. El retiro de efectivo estará disponible al aceptar sus datos.'
        }
        if (status.level === 'rejected') {
          message = 'Sus datos han sido rechazados. Llene el formulario de nuevo para autorizar la compra de efectivo.'
        }
        await Swal.fire({
          icon: 'error',
          title: message,
          confirmButtonText: 'Aceptar'
        })
        if (status.level === 'requested') {
          history.push('/')
          return
        }
        history.push('/kyc')
      } catch (e) {

      }
    }
    checkKYCStatus()
  }, [])

  useEffect(() => {
    if (networkId && !selectedStablecoin) {
      setSelectedStablecoin(addressesByNetwork[networkId][0])
    }
  }, [networkId])
  const actualAmount = (+amount + +comissionBase + (amount * (comissionPercent / 100)))
  const history = useHistory();
  const handleSend = async () => {
    if (amount < 2000 && !deliveryLocation) {
      await Swal.fire({
        icon: 'error',
        title: 'Seleccione el lugar de entrega',
        confirmButtonText: 'Aceptar'
      })
      return
    }
    try {
      setPageLoading(true)
      const tx = await sendStableCoin(selectedStablecoin.address, actualAmount)
      await signTransaction(selectedStablecoin.address, amount, actualAmount, tx.transactionHash, deliveryLocation, async () => {
        setPageLoading(false)
        await Swal.fire({
          icon: 'success',
          title: 'Venta solicitada',
          text: 'Pronto aprobaremos su petición',
          confirmButtonText: 'Aceptar'
        })
        history.push('/my-transactions')
      })
    } catch (error) {
      console.log(error)
      setPageLoading(false)
    }
  }

  return (
    <div className="home">
      <div className="sign-box">
        <h2 style={{ marginBottom: '1em' }}>Comprar efectivo</h2>
        <div className="buy-section">
          <div className="currency-selector">
            <input type='number' value={amount} onChange={e => setAmount(parseInt(e.target.value || '0').toString() || '0')} />
            <label className="usd-label">USD</label>
          </div>
          <img src={arrow} />
          <div className="stablecoin-selector">
            {!!amount && amount >= minAmount && !!account && <>
              {actualAmount}
              <select onChange={e => setSelectedStablecoin(JSON.parse(e.target.value))}>
                {networkId && addressesByNetwork[networkId].map(token => (
                  <option value={JSON.stringify(token)} key={token.name}>{token.name}</option>
                ))}
              </select>
            </>
            }
            {!account && <p className="small-text">Conecta tu wallet <br /> para poder usar <br /> Teller</p>}
            {amount < minAmount && ` No disponible`}
          </div>
        </div>
        {!!amount && amount >= minAmount && amount % 10 === 0 && <div className="comissions">
          <p>Comisión de {comissionPercent}%: {(amount * (comissionPercent / 100))} USD</p>
          <p>Costo de entrega: {comissionBase} USD</p>
        </div>
        }
        {amount < minAmount && <div className="comissions">El monto a retirar no puede ser menor de {minAmount} USD</div>}
        {amount >= minAmount && amount % 10 !== 0 && <div className="comissions">El monto a retirar debe ser múltiplo de 10</div>}
        <label className="blocky">Lugar de entrega: </label>
        {amount >= 2000 ?
          <p>
            Para montos superiores a 2000 USD el retiro de fondos debe ser realizado en persona en: XXXXXXX XXXXXXXXXXX XXXXXX XXXXXX
          </p>
          :
          <select className="blocky" onChange={e => setDeliveryLocation(e.target.value)} >
            <option selected disabled>Seleccione el lugar de entrega</option>
            {locations.map((l, i) => (
              <option value={l} key={i}>{l}</option>
            ))}
          </select>
        }
        {typeof window.ethereum === 'undefined' ? (
          <a className='not-installed' href="https://metamask.io/" target="_blank" rel="noreferrer">
            <button>
              Instala tu Wallet <img src={metamask} alt="" />
            </button>
          </a>)
          :
          <>
            <button onClick={handleSend} disabled={amount < minAmount || !amount || amount % 10 !== 0 || !account} >Comprar</button>
            {!account && <p>Conecta tu wallet para comprar efectivo</p>}
          </>
        }
      </div>
    </div>
  )
}
