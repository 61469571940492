import React, { useState } from "react"
import { Link } from "react-router-dom"
import Swal from "sweetalert2"
import Input from '../components/Input'
import { useHistory } from 'react-router-dom';

const cedulaRegexp = /[VE]-[0123456789]{1,8}/

export default function SignUp() {
  const history = useHistory();
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConf, setPasswordConf] = useState('')
  const [identification, setIdentification] = useState('')
  const mailChange = e => { setEmail(e.target.value) }
  const passwordChange = e => { setPassword(e.target.value) }
  const passwordConfChange = e => { setPasswordConf(e.target.value) }
  const handleSubmit = () => {
    if (password !== passwordConf) {
      Swal.fire({ icon: 'error', title: 'Error', text: "Verifica que tu confirmación de contraseña sea correcta" })
      return
    }
    fetch('/api/signup', {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(response => {
        if (response.ok) {
          history.push('/signIn')
        } else {
          Swal.fire({ icon: 'error', title: 'Error', text: response.error });
        }
      })
  }
  return (
    <div className="home">
      <div className="sign-box">
        <h2>Crear cuenta</h2>
        <Input name="Correo" label="email" onChange={mailChange} value={email} placeholder="uhura@enterprise.org" />
        <Input name="Contraseña" label="password" onChange={passwordChange} value={password} isPassword placeholder="********" />
        <Input name="Confirmar contraseña" label="password" onChange={passwordConfChange} value={passwordConf} isPassword placeholder="********" />
        <button onClick={handleSubmit}>Crear cuenta</button>
        <span>Ya tienes una cuenta? <Link to="signIn">Iniciar sesión</Link></span>
      </div>
    </div>
  )
}
