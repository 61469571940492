import React from "react"

export default function Input({
  name,
  label,
  onChange,
  value,
  placeholder,
  isPassword
}) {
  return (
    <div className="input">
      <label htmlFor={label}>{name}</label>
      <input
        id={label}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        type={isPassword ? 'password' : 'text'}
      />
    </div>
  )
}
