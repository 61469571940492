import React from "react";
import * as ReactDOMClient from 'react-dom/client';

import "./index.css";
import App from "./App";

import { Web3Provider } from "./web3";

const root = ReactDOMClient.createRoot(
  document.getElementById("root")
);

root.render(
  <Web3Provider>
    <App />
  </Web3Provider>
)
