import React, { useState, useContext } from "react"
import { Link } from "react-router-dom"
import Input from '../components/Input'
import { useHistory } from 'react-router-dom';
import Swal from "sweetalert2"
import { AppContext } from "../context";

export default function SignIn() {
  const { login } = useContext(AppContext)
  const history = useHistory();
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const mailChange = e => { setEmail(e.target.value) }
  const passwordChange = e => { setPassword(e.target.value) }
  const handleSignIn = () => {
    fetch('/api/signin', {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(response => {
        if (response.ok) {
          login(response.token)
          history.push('/')
        } else {
          Swal.fire({ icon: 'error', title: 'Error', text: response.error });
        }
      })
  }
  return (
    <div className="home">
      <div className="sign-box">
        <h2>Iniciar Sesión</h2>
        <Input name="Correo" label="email" onChange={mailChange} value={email} placeholder="uhura@enterprise.org" />
        <Input name="Contraseña" label="password" onChange={passwordChange} value={password} isPassword placeholder="********" />
        <button onClick={handleSignIn}>Iniciar Sesión</button>
        <span>No tienes cuenta? <Link to="signUp">Crear cuenta</Link></span>
      </div>
    </div>
  )
}
